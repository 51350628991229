import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import { Buffer } from 'buffer';

// window.Buffer = Buffer;

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// Register the service worker
serviceWorkerRegistration.register();
