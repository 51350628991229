export const dontbuyArtworks = [
    {
        "id": "dontbuy-bird",
        "name": "Bird",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_bird.jpg?alt=media&token=4cc5e2d7-cf58-4f6b-9b0b-f39564da740e",
        "type": "image/jpeg"
    },
    {
        "id": "dontbuy-blue-flag",
        "name": "blue flag of death",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_blue_flag.webm?alt=media&token=db8a3ae5-c32b-49da-bc37-bd0da99829ea",
        "type": "video/webm"
    },
    {
        "id": "dontbuy-bones",
        "name": "bones",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_bones.webm?alt=media&token=971fc956-4e98-4fdf-a636-2f6f40ab65e1",
        "type": "video/webm"
    },
    {
        "id": "dontbuy-cpu-overheating",
        "name": "CPU overheating",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_cpu_overheating.gif?alt=media&token=3dce5dc4-5ceb-488d-87bb-20053a9c71a3",
        "type": "image/gif"
    },
    {
        "id": "dontbuy-hedonist",
        "name": "Hedonist",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_hedonist.jpg?alt=media&token=d87f9504-fad2-4861-8ffc-89b23f92151e",
        "type": "image/jpeg"
    },
    {
        "id": "dontbuy-immortals",
        "name": "Immortals",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_immortals.webm?alt=media&token=39f36b45-43f6-40a2-a009-25d5f15bae62",
        "type": "video/webm"
    },
    {
        "id": "dontbuy-marine",
        "name": "Marine",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_marine.webm?alt=media&token=6705fc73-715b-49bf-9131-79f8e1c1e67f",
        "type": "video/webm"
    },
    {
        "id": "dontbuy-recycle-bin",
        "name": "Recycle Bin 200 B.C.",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_recycle_bin.webm?alt=media&token=52d68335-160e-41c7-b22c-3b4ba8ca7269",
        "type": "video/webm"
    },
    {
        "id": "dontbuy-rocking-horse",
        "name": "ROCKING_HORSE",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_rocking_horse.gif?alt=media&token=6e6b9aee-5908-44a3-91fc-a26612f99f0e",
        "type": "image/gif"
    },
    {
        "id": "dontbuy-skin",
        "name": "skin",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_skin.gif?alt=media&token=06f50c15-a3ce-4f4e-a22a-ef1105164a0c",
        "type": "image/gif"
    },
    {
        "id": "dontbuy-st",
        "name": "St.",
        "artist_name": "don't Buy",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2Fdontbuy%2Fdontbuy_st.jpg?alt=media&token=5db67de2-8fa2-4695-bf9e-cc5c0e4cb2e2",
        "type": "image/jpeg"
    }
];
