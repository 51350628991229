import { createWeb3Modal } from '@web3modal/wagmi'
import { createConfig, http } from 'wagmi'
import { 
    mainnet, zora 
  } from 'viem/chains'
import { walletConnect, coinbaseWallet, injected } from '@wagmi/connectors'
import { getAccount } from '@wagmi/core'

import * as db from '../firebase/firebaseDB'

async function parseTezosData(data) {
  const nft = data[6];
  if (nft){
    console.log(nft);
    console.log(nft.token.metadata.creators)
  const creatorAddr = nft.token.metadata.creators[0];
  const responce = await fetch(`${tezosUrl}accounts/${creatorAddr}`);

  console.log(await responce.json());
  }
}
const GRAPHQL_ENDPOINT = 'https://data.objkt.com/v3/graphql';
// const USER_ADDRESS = 'tz1VSUr8wMiM6t3b6u5F9d8c11Zj4EgR2r7s'; // replace with the user's address

// Define the GraphQL query
const query = `
  query ($address: String!) {
    holder(where: {address: {_eq: $address}}) {
      alias
      description
      inserted_at
      last_metadata_update
      logo
      medium
      reddit
      created_tokens {
        token {
          description
          name
          mime
          timestamp
          token_id
          artifact_uri
        }
      }
      held_tokens {
        token {
          creators {
            holder {
              address
              alias
            }
          }
          description
          name
          mime
          fa_contract
          token_id
          thumbnail_uri
          artifact_uri
          pk
        }
      }
    }
  }
`;

async function getTezosInfo(address) {
  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: query,
      variables: {
        address: address,
      },
    }),
  });

  if (!response.ok) {
    throw new Error(`GraphQL query failed with status ${response.status}`);
  }

  const result = await response.json();
  // return result.data.user.nfts;
  console.log(result);
}

async function createWallet(pubkey, userId, blockchain) {
  const result = await db.createWallet(pubkey, userId, blockchain);
  return result;
}

// Your WalletConnect Cloud project ID
export const projectId = 'c5e6fd015a12d949152933d91ae64c8d'
const openseaUrl = 'https://api.opensea.io/api/v2/chain';
const tezosUrl = 'https://api.tzkt.io/v1/';

// Create a metadata object
const metadata = {
  name: 'Holst',
  description: 'Enjoy artworks!',
  url: window.location.origin, //'https://artvote.holst.city', // origin must match your domain & subdomain
  icons: ['https://static.tildacdn.com/tild6533-6131-4230-b730-633538303730/holst-ico-2.ico']
}

// Create wagmiConfig
const chains = [mainnet, zora]

const connectors = []
connectors.push(walletConnect({ projectId, metadata, showQrModal: false }));
connectors.push(injected({ shimDisconnect: true }));
connectors.push(coinbaseWallet({
  appName: metadata.name,
  appLogoUrl: metadata.icons[0],
}));

const wagmiConfig = createConfig({
  chains,
  projectId,
  metadata,
  auth: {
    email: false
  },
  connectors: connectors,
})

const modal = createWeb3Modal({
  wagmiConfig: wagmiConfig,
  projectId,
  chains: chains
})

const fetchNFTs = async (address, callback) => {
    async function parseResponceAndCheck(responce, callback) {
        try {
            const data = await responce.json();
            allNFTs.push(data);
            // parseTezosData(data);
        } catch {
            console.log('Error in converting responce into JSON.');
        }
        if (++fetchedCount === countToFetch){
            callback(allNFTs);
        }
    }

    let allNFTs = [];
    let countToFetch = 3;
    let fetchedCount = 0; 

    console.log(getAccount(wagmiConfig));

    const options = {method: 'GET', headers: {accept: 'application/json', 'x-api-key': 'e8e697203f2145038ebc59717f5ee57a'}};

    fetch(`${openseaUrl}/ethereum/account/${address}/nfts`, options).then(responceEth => parseResponceAndCheck(responceEth, callback));
    fetch(`${openseaUrl}/zora/account/${address}/nfts`, options).then(responceZora => parseResponceAndCheck(responceZora, callback));
    fetch(`${tezosUrl}tokens/balances?account=${'tz1QvMqPkiVS7km2v7Cv9br56njn6FxRR8L6'}&token.standard=fa2`).then(responceTez => parseResponceAndCheck(responceTez, callback));
    await getTezosInfo('tz1QvMqPkiVS7km2v7Cv9br56njn6FxRR8L6');
    await createWallet('test_key', 'test_use', 'tezos');
}
  

export {wagmiConfig, modal, fetchNFTs }