import {db, auth} from './firebaseConfig';
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc, setDoc } from "firebase/firestore";

export async function getAllUsers() {
    const usersCollection = collection(db, 'users_test');

    try {
        const querySnapshot = await getDocs(usersCollection);
        let users = {};
        querySnapshot.forEach((doc) => {
            users[doc.id] = doc.data();
        });
        return users;
    } catch (error) {
        console.error('Error getting documents: ', error);
    }
}

export async function getOrSetUser(userId, userData) {
    const curDocRef = doc(db, 'users_test', userId);
    const snap = await getDoc(curDocRef);
    if (snap.exists()) {
        return snap.data();
    } else {
        try {
            const userDocRef = doc(collection(db, 'users_test'));
        
            // Set user document with the userId included as a field
            await setDoc(userDocRef, {
            ...userData,
            id: userDocRef.id,
            });
        
            return { id: userDocRef.id, ...userData };
        } catch (e) {
            console.error('Error creating user document: ', e);
            throw e;
        }
    }
}

export async function getUserFromTwitterId(twitterId) {
    const usersCollection = collection(db, 'users_test');
    const q = query(usersCollection, where('twitter_id','==', twitterId));
    try {
        const querySnapshot = await getDocs(q);
        let user = null;
        if (querySnapshot.size === 1) {
            user = querySnapshot.docs[0].data();
        }
        return user;
    } catch (error) {
        console.error('Error getting documents: ', error);
    }
}

export async function createWallet(pubkey, userId, blockchain) {
    const walletsCollection = collection(db, 'wallets');
    const q = query(walletsCollection, where('user_id','==', userId), where('pubkey', '==', pubkey));
    const querySnapshot = await getDocs(q);
    if (querySnapshot.size > 0) {
        console.log(querySnapshot.docs[0].data()['id']);
        return querySnapshot.docs[0].data()['id'];
    }
    console.log('Need to create');
    return 'LALALA';


    const walletDocRef = doc(collection(db, 'wallets'));
        
    await setDoc(walletDocRef, {
        id: walletDocRef.id,
        user_id: userId,
        pubkey: pubkey,
        blockchain: blockchain,
        last_sync_timestamp: Date.now(),
        status: 'syncing'
    });
        
    return { id:walletDocRef.id };
}