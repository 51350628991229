export const Art_vildtgrube = [
    {
        "id": "art_vildtgrube-gf_0007",
        "name": "GF_0007.",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FGF_0007.webm?alt=media&token=b992dee8-62c0-4b90-bc58-2b257dc3b67e",
        "type": "video/webm"
    },
    {
        "id": "art_vildtgrube-gf_0008",
        "name": "GF_0008.",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FGF_0008.webm?alt=media&token=95e9d2ef-7499-4a1e-aa81-02114ecb915a",
        "type": "video/webm"
    },
    {
        "id": "art_vildtgrube-mutation_0057",
        "name": "Mutation_0057.",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FMutation_0057.jfif?alt=media&token=8c6c107a-73f5-458f-9473-889eab782236",
        "type": "image/jpeg"
    },
    {
        "id": "art_vildtgrube-l_0016.",
        "name": "L_0016.",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FL_0016.webm?alt=media&token=c0e0d53b-e64b-4905-a242-3678e704c9c1",
        "type": "video/webm"
    },
    {
        "id": "art_vildtgrube-l_0008.",
        "name": "L_0008.",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FL_0008.webm?alt=media&token=9d2c74db-fd4e-430c-a782-990e60332d46",
        "type": "video/webm"
    },
    {
        "id": "art_vildtgrube-gf_0009",
        "name": "GF_0009.",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FGF_0009.webm?alt=media&token=62582a45-2a5a-4d8e-aa57-297f80162763",
        "type": "video/webm"
    },
    {
        "id": "art_vildtgrube-l_0006",
        "name": "L_0006",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FL_0006.webm?alt=media&token=ea11d858-4fc2-45b0-978a-ec73be51f7f6",
        "type": "video/webm"
    },
    {
        "id": "art_vildtgrube-l_0085",
        "name": "L_0085",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FL_0085.webm?alt=media&token=b57ef7df-13b2-4746-b7b0-7b35547bb4f7",
        "type": "video/webm"
    },
    {
        "id": "art_vildtgrube-attribute_rotate",
        "name": "Attribute_Rotate.",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FAttribute_Rotate.webm?alt=media&token=a650b0a9-6738-446e-b142-fbb11eaf96dd",
        "type": "video/webm"
    },
    {
        "id": "art_vildtgrube-l_0033",
        "name": "L_0033.",
        "artist_name": "Art_vildtgrube",
        "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artists-exhibitions%2FArt_vildtgrube%2FL_0033.webm?alt=media&token=8d28601a-6cc9-4011-9a08-4946fce95bc8",
        "type": "video/webm"
    },
];
