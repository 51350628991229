import React from 'react';
import SwiperComponent from './SwiperComponent/SwiperComponent';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TwitterCallback from './Auth/auth'
import { WagmiProvider } from 'wagmi'
import { wagmiConfig, modal } from './WalletConnect/Wallet';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();

  return (
  <>
  <WagmiProvider config={wagmiConfig}>
    <Router>
      <Routes>      
        <Route path="/" element={<SwiperComponent />} /> {/* Your home or main component */}
        <Route path="/callback" element={<TwitterCallback />} />
      </Routes>
    </Router>
    {/* <QueryClientProvider client={queryClient}>
          <w3m-button />
      </QueryClientProvider> */}
  </WagmiProvider>
  </>   
  );
};

export default App;
