import {googleProvider, twitterProvider, auth} from '../firebase/firebaseConfig'
import { GoogleAuthProvider, TwitterAuthProvider, signInWithPopup, signInWithRedirect, getAuth, signOut } from "firebase/auth";
import { serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useEffect } from "react";
import * as db from '../firebase/firebaseDB'

export let currnetUser = null;

// Sign in with Google
const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.log("Google Sign-in successful:", user);
      console.log('ID -', user.uid);
      const userData = {
        created_at: user.reloadUserInfo.createdAt ? user.reloadUserInfo.createdAt: Date.now(),
      }
      currnetUser = await db.getOrSetUser(user.uid, userData);
      console.log(currnetUser);
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error("Google Sign-in error:", errorCode, errorMessage, email, credential);
    }
    console.log(await db.getAllUsers());
  };

const client_id = 'MUdKWklra2tYMGxGY25jbVFuMU46MTpjaQ';
const redirect_uri = `${window.location.origin}/callback`; // Change this to your actual redirect URI
const auth_url = 'https://twitter.com/i/oauth2/authorize';
const token_url = 'https://api.twitter.com/2/oauth2/token';
const scopes = 'tweet.read tweet.write users.read';
const info_url = 'https://api.twitter.com/2/users/me';
const firebaseFunctionURL = 'https://us-central1-holst-a07f5.cloudfunctions.net/corsProxy?url=';

const generatePkcePair = async () => {
  const codeVerifier = base64UrlEncode(crypto.getRandomValues(new Uint8Array(32)));
  const codeChallenge = base64UrlEncode(await sha256(codeVerifier));
  return { codeVerifier, codeChallenge };
};

const base64UrlEncode = (buffer) => {
  return btoa(String.fromCharCode.apply(null, new Uint8Array(buffer)))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
};

const sha256 = async (plain) => {
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  const hashBuffer = await crypto.subtle.digest('SHA-256', data);
  return new Uint8Array(hashBuffer);
};
  
// Sign in with Twitter
const signInWithTwitter = async () => {
  const { codeVerifier, codeChallenge } = await generatePkcePair();
  sessionStorage.setItem('codeVerifier', codeVerifier);

  const authEndpoint = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=tweet.read%20tweet.write%20users.read&state=state&code_challenge=${codeChallenge}&code_challenge_method=S256`;  // `${auth_url}?response_type=code&client_id=${client_id}&redirect_uri=${redirect_uri}&scope=${scopes}&state=state&code_challenge=${codeChallenge}&code_challenge_method=S256`;
  window.location.href = authEndpoint;
};

async function getOrCreateUserFromTwitter(userInfo) {
  const twitterId = userInfo.data.id;
  let user = await db.getUserFromTwitterId(twitterId);
  if (!user) {
    const userData = {
      twitter_id: twitterId,
      created_at: Date.now(),
    };

    user = await db.getOrSetUser(null, userData);
  } else {
    console.log('Congrats!');
  }
  return user;
}

  // Callback component to handle Twitter OAuth 2.0 response
const TwitterCallback = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
    const getAccessToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const codeVerifier = sessionStorage.getItem('codeVerifier');
  
      if (!code || !codeVerifier) {
        console.error('No code or code verifier found');
        return;
      }
  
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        // 'access-control-allow-origin': '*',
        // 'access-control-max-age': '3600',
        // 'access-control-allow-methods': '*',
        // 'access-control-request-headers': '*',
      };

      try {
        const response = await fetch(`https://us-central1-holst-a07f5.cloudfunctions.net/proxyToken`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: code,
            codeVerifier: codeVerifier,
            redirectUri: redirect_uri,
            clientId: client_id
          })
        });

        const responseBody = await response.text();
        console.log("Response status:", response.status);
        console.log("Response body:", responseBody);
  
        if (response.ok) {
          const tokenData = JSON.parse(responseBody);
          console.log('Access Token:', tokenData.access_token);
          // Save the access token in session storage or use it directly
          sessionStorage.setItem('access_token', tokenData.access_token);

          // Fetch user information using the access token
          const userInfo = await fetchUserInfo(tokenData.access_token);
          currnetUser = await getOrCreateUserFromTwitter(userInfo)
          console.log(currnetUser);
        } else {
          console.error('Error obtaining access token:', responseBody);
        }
      } catch (error) {
        console.error('Error obtaining access token:', error);
      }
    };

    const fetchUserInfo = async (accessToken) => {
      try {
        const response = await fetch(`https://us-central1-holst-a07f5.cloudfunctions.net/getUserInfo`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ accessToken })
        });

        if (response.ok) {
          const userInfo = await response.json();
          console.log('User Info:', userInfo);
          // Store user information in session storage or state
          sessionStorage.setItem('user_info', JSON.stringify(userInfo));
          navigate('/'); // Redirect to home or any other page
          return userInfo;
        } else {
          console.error('Error fetching user info:', await response.text());
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
      return null;
    };
  
    getAccessToken();
  }, [navigate]);
  
  return <div class="loader-container">
    <div class="loader"></div>
    </div>;
};
  
  // Sign out
const signOutUser = async () => {
  try {
    await signOut(auth);
    console.log("Sign-out successful");
  } catch (error) {
    console.error("Sign-out error:", error);
  }
};
  
export { signInWithGoogle, signInWithTwitter, signOutUser };
export default TwitterCallback;
  