import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, TwitterAuthProvider } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDmhtlF4RkwaR6jY4gvMwV4nJJnEUw1zFo",
  authDomain: "holst-a07f5.firebaseapp.com",
  projectId: "holst-a07f5",
  storageBucket: "holst-a07f5.appspot.com",
  messagingSenderId: "83755329024",
  appId: "1:83755329024:web:e50820396c035f67419ae5",
  measurementId: "G-3JWZ4VMRZ9"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const twitterProvider = new TwitterAuthProvider();
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { auth, googleProvider, twitterProvider, db };