export const artworks = [
        {
            "id": "nuv",
            "name": "de frutillas",
            "artist_name": "Nuv",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2Fde%20frutillas.png?alt=media&token=65321e12-cdec-4aef-bb8e-791bcd251596",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fnuv.png?alt=media&token=0df37e0f-fd1b-4b76-a573-6456a95fa0f6",
            "type": "image/png"
        },
        {
            "id": "olaf-not-funny",
            "name": "This Is Not Funny",
            "artist_name": "OLAF",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FThis%20Is%20Not%20Funny.gif?alt=media&token=5bf13127-2559-4fdf-a856-70f14b1a76e8",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Folaf-not-funny.png?alt=media&token=3f269521-990c-4f16-8cc0-01d921b74e18",
            "type": "image/gif"
        },
        {
            "id": "ugo-trojan",
            "name": "Trojan",
            "artist_name": "Ugo",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FTrojan.gif?alt=media&token=5ba10689-16ac-4ead-a8f6-ed74162b31b5",
            "qr_src": "http://holst.city/uao/ugo-trojan",
            "type": "image/gif"
        },
        {
            "id": "ceren-su",
            "name": "Smokers",
            "artist_name": "Ceren Su",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/webVideos%2Fceren-su.webm?alt=media&token=6c860401-2892-4689-960c-301d2caf248f",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fceren-su.png?alt=media&token=3de4793d-fc41-4c64-9afb-650bd39c1063",
            "type": "video/mp4"
        },
        {
            "id": "galih",
            "name": "born2wild  2.0",
            "artist_name": "Galih",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2Fborn2wild%202.0.jpg?alt=media&token=11a90fe4-63ec-42a3-aa15-6d60b3fc4db9",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fgalih.png?alt=media&token=e1f3dcb2-1dcc-45a3-8d44-9f683eebd391",
            "type": "image/jpeg"
        },
        {
            "id": "ilya-bliznets",
            "name": "Trying to focus",
            "artist_name": "Ilya Bliznets",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FTrying%20to%20focus.jpg?alt=media&token=d88083d8-57fc-4870-bb76-1ecf59351867",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Filya-bliznets.png?alt=media&token=0a83ab7c-fd74-412a-9033-aaa4be0115d1",
            "type": "image/jpeg"
        },
        {
            "id": "zisis-bliatkas",
            "name": "Search \ud835\udd26\ud835\udd2b\ud835\udd30\ud835\udd26\ud835\udd24\ud835\udd25\ud835\udd31 Engine",
            "artist_name": "Zisis Bliatkas",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2Fzisis-bliatkas.gif?alt=media&token=d7232142-122d-4e5e-a8b1-d6843fdfe537",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fskomra.png?alt=media&token=9e6c2f35-bf34-426b-bada-72119d5540db",
            "type": "image/gif"
        },
        {
            "id": "olaf-memory-card",
            "name": "The Memory Card",
            "artist_name": "OLAF",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FThe%20Memory%20Card.gif?alt=media&token=d267d1e8-a40a-4c96-811d-4204800a5247",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Folaf-memory-card.png?alt=media&token=7ee72339-c87c-4e60-b7c7-d5a8f4f36640",
            "type": "image/gif"
        },
        {
            "id": "scerbo",
            "name": "No-Empathy_No-Auction",
            "artist_name": "Scerbo",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/webVideos%2Fscerbo.webm?alt=media&token=b200ed9f-9688-4ef7-8f92-edd52ec3edfb",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fscerbo.png?alt=media&token=b8467999-271f-47d5-b85f-adfe4edb632e",
            "type": "video/mp4"
        },
        {
            "id": "daniel-jerome",
            "name": "Fallen Ones Fall In Two",
            "artist_name": "Daniel Jerome",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FFallen%20Ones%20Fall%20In%20Two.jpg?alt=media&token=d30cdbc9-afa2-4117-b578-808b10206473",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Ftimur-nugmanov.png?alt=media&token=177b4aa7-e7ec-4f87-8108-9d55a192f379",
            "type": "image/jpeg"
        },
        {
            "id": "solar",
            "name": "ZIP002",
            "artist_name": "Solar",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FZIP002.gif?alt=media&token=3603a372-d6cc-4a2a-acc8-92a69fe347e1",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fsolar.png?alt=media&token=60a547e4-37f5-4745-bdcb-b682456fb6f6",
            "type": "image/gif"
        },
        {
            "id": "ugo-xp",
            "name": "Windows XP",
            "artist_name": "Ugo",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FWindows%20XP.gif?alt=media&token=cc72867f-a6cf-4306-a805-7d49333e1e3b",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fugo-xp.png?alt=media&token=e2cbb7e7-ebb0-4d2e-8f23-ec90ca1af5cc",
            "type": "image/gif"
        },
        {
            "id": "noper",
            "name": "Tornado In My Head",
            "artist_name": "Noper",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FTornado%20In%20My%20Head.gif?alt=media&token=1224f381-6bbf-4d4e-b608-0411ac690117",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fnoper.png?alt=media&token=565881f2-2fac-4ccf-8429-4eeadae68f8d",
            "type": "image/gif"
        },
        {
            "id": "ni-evaporation",
            "name": "Evaporation",
            "artist_name": "Ni",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/webVideos%2Fni-evaporation.webm?alt=media&token=9e90bca0-86e7-46c4-ac2f-71882c18d431",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fni-evaporation.png?alt=media&token=c0b7c546-ec0a-40d7-bd31-14062c4d0228",
            "type": "video/mp4"
        },
        {
            "id": "pipi-presidente",
            "name": "Presidente de Internet",
            "artist_name": "PiPi",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/webVideos%2Fpipi-presidente.webm?alt=media&token=7595d2ab-8c0c-45a4-b423-145ca8a0e1cf",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fpipi-presidente.png?alt=media&token=14e3fee6-25d4-4a83-9663-ef4372d748a8",
            "type": "video/mp4"
        },
        {
            "id": "timur-nugmanov",
            "name": "Involved",
            "artist_name": "Timur Nugmanov",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FInvolved.gif?alt=media&token=0fd1b7bc-8627-4033-a2ea-6c268900a384",
            "qr_src": "http://holst.city/uao/timur-nugmanov",
            "type": "image/gif"
        },
        {
            "id": "pipi-pipicop",
            "name": "PipiCop Wife Undercover Escaping From Nuevo Me\u0301xico",
            "artist_name": "PiPi",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FPipiCop%20Wife%20Undercover%20Escaping%20From%20Nuevo%20México.gif?alt=media&token=255146d6-d10a-4717-bd97-47f53fe1322e",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fpipi-pipicop.png?alt=media&token=71076778-8f5a-42ce-a87f-9ed14af26261",
            "type": "image/gif"
        },
        {
            "id": "l444u",
            "name": "sometimes i feel like i never existed",
            "artist_name": "L444u",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/webVideos%2Fl444u.webm?alt=media&token=8072c792-af13-47d2-8451-137b3e55198f",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fl444u.png?alt=media&token=64a56ffc-08ad-46f0-8b14-0970e5ef5d2d",
            "type": "video/mp4"
        },
        {
            "id": "olaf-finger",
            "name": "The Finger",
            "artist_name": "OLAF",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FThe%20Finger.gif?alt=media&token=dffde750-efe8-48a7-8037-bbb5ad0559d5",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fugo-trojan.png?alt=media&token=9b913b5a-aa9f-4dea-a95d-ff0c3bd4e646",
            "type": "image/gif"
        },
        {
            "id": "skomra",
            "name": "Study for self portrait in an AI image enhancer",
            "artist_name": "Skomra",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FStudy%20for%20self%20portrait%20in%20an%20AI%20image%20enhancer.png?alt=media&token=59046bed-2ae3-452f-b139-58d2907b869b",
            "qr_src": "http://holst.city/uao/skomra",
            "type": "image/png"
        },
        {
            "id": "salawaki",
            "name": "My Little Rave Machine",
            "artist_name": "salawaki",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/webVideos%2Fsalawaki.webm?alt=media&token=ae396d91-c07e-429e-801e-496f84a389e5",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fsalawaki.png?alt=media&token=edf8e51f-377a-4946-b66e-09fe9f3c3369",
            "type": "video/mp4"
        },
        {
            "id": "martn-bruc",
            "name": "Pink Rose Teleportation",
            "artist_name": "martn bruc",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/artworksTV%2FPink%20Rose%20Teleportation.jpg?alt=media&token=115a9c26-e89c-4391-be89-daf80f78d467",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fmartn-bruc.png?alt=media&token=fb0b57b8-e492-49ed-93b9-805dd80cec7b",
            "type": "image/jpeg"
        },
        {
            "id": "ni-crosswalk",
            "name": "crosswalk_001",
            "artist_name": "Ni",
            "src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/webVideos%2Fni-crosswalk.webm?alt=media&token=b7b946d1-c960-4992-91a9-c2ee2965b0aa",
            "qr_src": "https://firebasestorage.googleapis.com/v0/b/holst-a07f5.appspot.com/o/qrforTVartworks%2Fni-crosswalk.png?alt=media&token=e59f76cb-260c-4d6d-bf29-11fa80262653",
            "type": "video/mp4"
        }
    ]